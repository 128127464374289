






















































































import Vue from 'vue'
import {
  mapGetters,
  mapActions,
  mapState
} from 'vuex'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import { cloneDeep, handleErrors } from '@/utils/functions'

export default Vue.extend({
  name: 'ModalEditCatalog',

  components: {
    ValidatedBFormInput
  },

  props: {
    catalog: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      localCatalog: {}
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    profileCurrency () {
      return this.profile?.profile_data?.currency === 417 ? 'KGS' : 'RUB'
    }
  },

  methods: {
    ...mapActions('catalog', [
      'updateCatalog',
      'createCatalog'
    ]),

    ...mapGetters('catalog', [
      'getNewCatalog'
    ]),

    initCatalog () {
      this.localCatalog = cloneDeep(this.catalog ?? this.getNewCatalog())
    },

    onModalShown () {
      this.initCatalog()
    },

    onModalHidden () {
      this.resetCatalog()
      this.$emit('hidden')
    },

    resetCatalog () {
      this.localCatalog = {}
    },

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      if (this.localCatalog.id) {
        this.handleUpdateCatalog()
      } else {
        this.handleCreateCatalog()
      }
    },

    async handleUpdateCatalog () {
      try {
        await this.updateCatalog({
          profileId: this.profile.profile_data.id,
          catalogId: this.localCatalog.id,
          data: {
            name: this.localCatalog.name,
            image_url: this.localCatalog.image_url,
            delivery_price: this.localCatalog.delivery_price,
            is_there_free_delivery: this.localCatalog.is_there_free_delivery,
            order_price_to_free_delivery: this.localCatalog.order_price_to_free_delivery,
            is_hidden: this.localCatalog.is_hidden
          }
        })
        this.$toast.success('Каталог обновлен')
        this.$bvModal.hide('modal-edit-catalog')
      } catch (error) {
        handleErrors(error)
      }
    },

    async handleCreateCatalog () {
      try {
        await this.createCatalog({
          profileId: this.profile.profile_data.id,
          data: {
            name: this.localCatalog.name,
            image_url: 'https://s3.bsl.dev/mardev/default_avatar.png',
            delivery_price: this.localCatalog.delivery_price,
            is_there_free_delivery: true,
            order_price_to_free_delivery: this.localCatalog.order_price_to_free_delivery
          }
        })
        this.$toast.success('Каталог успешно создан')
        this.$bvModal.hide('modal-edit-catalog')
      } catch {
      }
    }
  }
})
