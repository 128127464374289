































import Vue from 'vue'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'

export default Vue.extend({
  name: 'CatalogCard',

  props: {
    catalog: {
      type: Object
    }
  },

  data () {
    return {
      isMainCatalog: false
    }
  },

  created () {
    this.isMainCatalog = this.catalog.is_hidden
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    name () {
      return this.catalog.name ?? 'Без названия'
    }
  },

  watch: {
    catalog: {
      handler (val) {
        this.isMainCatalog = val.is_hidden
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations('common', [
      'setLoading'
    ]),

    ...mapActions('catalog', [
      'deleteCatalog'
    ]),

    toCatalog () {
      this.$router.push({
        name: 'profile-catalog',
        params: {
          profileId: this.$route.params.profileId,
          catalogId: this.catalog.id
        }
      })
    },

    changeCatalog () {
      this.$emit('change-catalog', this.catalog)
    },

    toggleCatalogMainStatus () {
      this.$emit('toggle-catalog-main-status', { catalogId: this.catalog.id, isMain: !this.catalog.is_hidden })
    },

    async handleDeleteCatalog () {
      if (!await this.$bvModal.msgBoxConfirm('Это действие нельзя отменить', {
        titleHtml: `Вы уверены что хотите удалить <span class="text-primary">${(this.catalog.name && this.catalog.name !== '') ? this.catalog.name : 'каталог'}</span>?`,
        okVariant: 'secondary',
        modalClass: 'modal-confirm',
        okTitle: 'Удалить',
        cancelVariant: 'primary',
        cancelTitle: 'Отмена'
      })) {
        return
      }

      try {
        this.setLoading(true)
        await this.deleteCatalog({
          profileId: this.profile.profile_data.id,
          catalogId: this.catalog.id
        })
        this.$toast.success('Каталог удалён')
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    }
  }
})
